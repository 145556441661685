import React from 'react';
import SponsorLogos from '../components/sponsors.js';

const Sponsors = () => (

<section>
    <SponsorLogos />
</section>

);

export default Sponsors;